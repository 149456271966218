export const columns = [
	{
		prop: 'name',
		label: '渠道名称',
	},
	{
		prop: 'companyList',
		label: '所属公司',
		formatter: function (row, column, cellValue, index) {
			if (!cellValue || cellValue.length === 0) {
				return '无'
			}
			return cellValue.map(item => item.companyName).join(',')
		},
	},
	{
		prop: 'status',
		label: '渠道状态',
		formatter: function (row, column, cellValue, index) {
			return cellValue === 1 ? '启用' : '禁用'
		},
	},
	/* {
		prop: 'rolePermissions',
		label: '联系人',
		formatter: function (row, column, cellValue, index) {
			return cellValue === 10 ? '个人' : '公司'
		},
	}, */
]

export const filters = {
	expand: false,
	filters: [
		{
			label: '渠道名称',
			attr: 'name',
			type: 'search',
			placeholder: '请输入渠道名称',
			clearable: true,
		},
		{
			attr: 'companyIds',
			label: '所属公司',
			type: 'multi-select',
			placeholder: '选择对应公司',
		},
		{
			attr: 'status',
			label: '渠道状态',
			type: 'select',
			placeholder: '请选择渠道状态',
			options: [
				{ name: '启用', code: 1 },
				{ name: '禁用', code: 0 },
			],
		},
	],
}

export const rules = {
	name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
	status: [{ required: true, message: '请选择状态', trigger: 'blur' }],
	companyIds: [{ required: true, message: '请选择合作公司', trigger: 'blur' }],
	useESign: [
		{
			required: true,
			message: '请选择电子合同',
			trigger: 'blur',
		},
	],
}
